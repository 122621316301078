import FlatButton from "./../classes/ui/flatButton";
import SoundButtons from "./../classes/ui/soundButtons";

export default class SceneTitle extends Phaser.Scene {
    constructor() {
        super('SceneTitle');
    }
    preload()
    {
       
    }
    create() {

        gtag('event', 'scene', {'title': 'Scene Title'});
        this.game.mm.setBackgroundMusic('backgroundMusic', this);       

        this.bg = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bg');

        //this.game.alignGrid.showNumbers(this);
        
        this.bg.setScale(1);

        this.title = this.add.image(0, 0, 'title');

        this.game.alignGrid.placeAtIndex(38, this.title);

        this.game.alignGrid.scaleToGameW(this.title, 0.8)

        this.textStyle = {
            fontSize: '6em',
            fontStyle: 'normal',
            fontFamily: 'PROXON',
            color: '#ffffff',
            align: 'center',
            lineSpacing: 2,
            wordWrap: {
                width: this.game.alignGrid.width * 0.8
            },
            
        }

        
        //this.soundButtons = new SoundButtons(this);

        this.helloText = this.add.text(0, 0, "Rhytmical typing method", this.textStyle);

        this.game.alignGrid.placeAtIndex(60, this.helloText);

        this.helloText.setOrigin(0.5, 0.5);

        this.btnStart = new FlatButton({scene: this, key: 'button1', text: 'play', event: 'start_game'});

        this.game.alignGrid.placeAtIndex(82, this.btnStart);

        this.game.ee.on('start_game', this.startGame, this);

        this.btnType = new FlatButton({scene: this, key: 'button1', text: 'type', event: 'start_typing'});

        this.game.ee.on('start_typing', this.startTyping, this);

        this.game.alignGrid.placeAtIndex(104, this.btnType);
        

        //Remove this line to activate scene
        //this.scene.start('SceneTwo');
    }
    startGame() {
        gtag('event', 'button', {'value': 'Start Game'});
        if (this.game.profile.score < 300) {
            this.scene.start('SceneOne');
        } else {
            this.scene.start('SceneTwo');
        }
        
    }

    startTyping() {

        gtag('event', 'button', {'value': 'Start Typing'});
        
        this.scene.start('SceneTyping');
        
        
    }

    update() {
        this.bg.rotation += 0.00025;
    }
}