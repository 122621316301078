export default class Sector {
    constructor(keyboard, sector, transmission = null) {
        this.hideOnTap = keyboard.hideOnTap;
        this.sectorPattern = sector.pattern;
        this.transmission = transmission;
        this.status = this.getSectorStatus();
        
        if (this.status != 0) {

            this.sectorPatternLength = this.removeZeros(this.sectorPattern).length - 1;
            this.centerX = sector.centerX;
            this.centerY = sector.centerY;
            this.letter = sector.letter;
            this.number = sector.number;
            this.notation = sector.notation;
            this.textAngle = sector.angle;
            
            this.thickness = keyboard.width / 14;
            this.radius = this.sectorPatternLength * keyboard.width / 10;
          
            this.oneOf = Math.pow(2, this.sectorPatternLength);
            this.offset = this.number - this.oneOf + 1;
    
            this.lineThickness = 8;
    
            this.length = 360 / this.oneOf;
            this.startDeg = this.offset * this.length - 90;
            this.startRad = Phaser.Math.DegToRad(this.startDeg);

            this.path = keyboard.scene.add.path();

            this.endDeg = this.offset * this.length + this.length - 90;
            this.endRad = Phaser.Math.DegToRad(this.endDeg);

            this.middleDeg = (this.endDeg - this.startDeg) / 2;
            this.curve = new Phaser.Curves.Ellipse(0, 0, this.radius + this.thickness / 2, this.radius + this.thickness / 2, this.startDeg, this.endDeg, false);
            this.path.add(this.curve);
            //this.path.draw(keyboard.graphics);

            if (this.sectorPatternLength < 1) {
                this.center = {x : 0, y: 0};
            } else {
                this.center = this.path.getPoint(0.5);
            }

    
            if (this.sectorPatternLength > 3) {
                this.startPadding = 0.03;
                this.endPadding = 0.025;
            } else if (this.sectorPatternLength > 2) {
                this.startPadding = 0.04;
                this.endPadding = 0.032;
            } else if (this.sectorPatternLength > 1) {
                this.startPadding = 0.06;
                this.endPadding = 0.045;
            } else if (this.sectorPatternLength > 0) {
                this.startPadding = 0.115;
                this.endPadding = 0.065;
            } else {
                this.startPadding = 0;
                this.endPadding = 0;
            }
            
            if (this.sectorPattern == '1') {
                this.bgColor = keyboard.colors.lightGrey
                this.lineColor = keyboard.colors.lightGrey
            } else if (this.sectorPattern == '11') {
                this.bgColor = keyboard.colors.darkGrey
                this.lineColor = keyboard.colors.darkGrey
            } else if (this.sectorPattern == '101') {
                this.bgColor = keyboard.colors.darkGrey
                this.lineColor = keyboard.colors.darkGrey
            } else if (this.sectorPattern.startsWith('1101')) {
                this.bgColor = keyboard.colors.yellow
                this.lineColor = keyboard.colors.yellow
            } else if (this.sectorPattern.startsWith('1011')) {
                this.bgColor = keyboard.colors.green
                this.lineColor = keyboard.colors.green
            } else if (this.sectorPattern.startsWith('10101')) {
                this.bgColor = keyboard.colors.blue
                this.lineColor = keyboard.colors.blue
            } else if (this.sectorPattern.startsWith('111')) {
                this.bgColor = keyboard.colors.red
                this.lineColor = keyboard.colors.red
            }
    
            if (this.status == 2) {
                this.bgColor = keyboard.colors.white
        
            } else if (this.status == 4) {
                this.bgColor = keyboard.colors.black
                this.lineColor = keyboard.colors.black
            }
        
            if (this.status == 2) {
                this.textColor = '#000000';
            } else  {
                this.textColor = '#ffffff';
            }

        }
        
    }

    removeZeros(pattern) {
        return pattern.replace(/0/g, '');
    }

    getSectorStatus() {
        
        //Show all if no transmission
        if (!this.transmission || !this.transmission.pattern) {
            return 1;
        } 

        //Hide all not possible
        if (!this.sectorPattern.startsWith(this.transmission.pattern) && !this.transmission.pattern.startsWith(this.sectorPattern)) {
            return 0;
        }

        //Highlight active
        if (this.transmission.pattern == this.sectorPattern && this.transmission.animationFrame < 3) {
            return 2;
        }  
        
        if (this.transmission.animationFrame == 2 && this.sectorPattern.startsWith(this.transmission.pattern + '1')) {
            return 0;
        }

        if (this.transmission.animationFrame > 2 && !this.transmission.pattern.startsWith(this.sectorPattern)) {
            return 0;
        }

    }
}