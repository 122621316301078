export default class MediaManager {
    constructor(game) {
        this.game = game;
        this.game.ee.on('play_sound', this.playSound, this);
        this.game.ee.on('music_changed', this.musicChanged, this);
        this.backgroundMusic = false;
    }

    musicChanged() {
        if (this.backgroundMusic) {
            if (this.game.profile.musicOn) {
                this.backgroundMusic.play();
            } else {
                this.backgroundMusic.stop();
            }
        }
    }

    playSound(key, scene) {
        if (this.game.profile.soundsOn) {
            var sound = scene.sound.add(key, {volume: .5});
            sound.stop();
            sound.play();
        }
    }

    setBackgroundMusic(key, scene) {
        if (this.backgroundMusic) {
            this.backgroundMusic.stop();
        }
        this.backgroundMusic = scene.sound.add(key, {volume: .5, loop: true});
        if (this.game.profile.musicOn) {
            this.backgroundMusic.play();
        }
    }
}