import patterns from "./patterns";
import Sector from "./sector";

export default class Keyboard extends Phaser.GameObjects.Container {
    constructor(scene, labels = false, limit = null, hideOnTap = false) {
        super(scene);
        this.ee = scene.game.ee;
        this.hideOnTap = hideOnTap;
        this.scene = scene;
        this.textStyle = {
            fontSize: '4em',
            fontStyle: 'bold',
            fontFamily: 'PROXON',
            stroke: '#383838',
            strokeThickness: 4,
        }
  
        this.graphics = this.scene.make.graphics();
        this.graphics.moveTo(0, 0);
        this.sectors = [];
        this.lastUpdate = 0;
        this.pattern = '';
        this.progress = 0;
        this.frame = 0;
        this.labels = labels;
        this.sectorLabels = [];
        this.limit = limit;
        this.patterns = this.getPatterns(this.limit);

        this.colors = {
            'red': 0xfe1a00,
            'yellow': 0xfed200,
            'green': 0x69c822,
            'blue': 0x007bc7,
            'lightGrey': 0x9f9f9f,
            'darkGrey': 0x424242,
            'white': 0xffffff,
            'black': 0x000000,
        }

        this.size = scene.game.alignGrid.width < scene.game.alignGrid.height / 2 ? scene.game.alignGrid.width : scene.game.alignGrid.height / 2;
        this.size = this.size * 0.8;
        this.setSize(this.size, this.size);
   
        this.getTextMetrics();
        //scene.tweens.add({targets: this, duration: 100, scale:1.05, yoyo: true });
        this.x = scene.game.config.width / 2;
        this.y = scene.game.config.height * 3 / 4;
        
        this.ee.on('transmission_interrupted', this.draw, this);
        this.ee.on('transmission_frame_updated', this.draw, this);
        this.ee.on('transmission_sent', this.feedback, this);
        
        //this.setScale(0.9);

        this.updateSectors();
        this.draw();

        this.scene.add.existing(this);
    }

    setLimit(limit) {
        this.limit = limit;
        this.patterns = this.getPatterns(this.limit);
    }

    updateSectors(transmission = null) {
        this.sectors = [];
        for (let i = 0; i < this.patterns.length; i++) {
            let sector = new Sector(this, this.patterns[i], transmission);
            if (sector.status != 0) {
                this.sectors.push(sector);           
            }
        }
    }

    clearSectorLabels() {

        for (let i = 0; i < this.sectorLabels.length; i++) {
            this.sectorLabels[i].destroy();
        }

        this.sectorLabels = [];

    }

    populateSectorLabels() {

        for (let i = 0; i < this.sectors.length; i++) {

            this.drawSectorLabel(this.sectors[i]);               

        }
    }

    draw(transmission) {
        
        this.removeAll();
        this.graphics.clear();
        this.updateSectors(transmission);

        for (let i = 0; i < this.sectors.length; i++) {
            this.drawSector(this.sectors[i]);    
            // if (this.sectors[i].status == 4 && this.labels) {
            //     this.drawSectorLabel(this.sectors[i]); 
            // }           
        }

        this.add(this.graphics);

        if (transmission || !this.labels) {
            this.clearSectorLabels();
            return;
        }

        this.populateSectorLabels();
        
        this.add(this.sectorLabels);
  
    }

    feedback() {
        this.scene.tweens.add({targets: this, duration: 100, scale:1.05, yoyo: true, onComplete: this.scene.clear, onCompleteScope: this.scene });
    }

    drawSector(sector) {
        
        this.graphics.fillStyle(sector.bgColor, 1);
        this.graphics.lineStyle(sector.lineThickness, sector.lineColor, 1);
        //  Without this the arc will appear closed when stroked
        if (sector.sectorPatternLength == 0) {
            this.graphics.fillCircle(0, 0, this.width / 14);
            this.graphics.strokeCircle(0, 0, this.width / 14);
        } else {
            this.graphics.beginPath();
        
            // arc (x, y, radius, startAngle, endAngle, anticlockwise)
            this.graphics.arc(0, 0, sector.radius, sector.startRad + sector.startPadding, sector.endRad - sector.startPadding, false);
            this.graphics.arc(0, 0, sector.radius + sector.thickness, sector.endRad - sector.endPadding, sector.startRad + sector.endPadding, true);
    
            //  Uncomment this to close the path before stroking
            this.graphics.closePath();
    
            this.graphics.strokePath();
            this.graphics.fillPath();
        }
        
    }

    drawSectorLabel(sector) {
        this.textStyle.color = sector.textColor;
        let text = this.scene.make.text({x: sector.center.x, y: sector.center.y, text: sector.letter, style: this.textStyle, add: false, metrics: this.textMetrics});
        text.setOrigin(0.5, 0.5);
        text.setAngle(sector.textAngle);
        this.sectorLabels.push(text);
    }

    getActiveSector() {
        return this.sectors.find((sector) => {return sector.status == 2});
    }

    randomSector() {
        return this.sectors[Math.floor(Math.random() * this.sectors.length)]
    }

    getTextMetrics() {
        let text = this.scene.add.text({x: 0, y: 0, text: 'Getting the font metrics', style: this.textStyle, add: false});
        this.textMetrics = text.getTextMetrics();
        text.destroy()
    }

    getPatterns(limit) {
        if (limit) {
            let output = patterns.filter((pattern) => {
                return pattern.number <= limit
            });
            return output;
        } else {
            return patterns;
        }
        
        
    }

}