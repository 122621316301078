export default class AlignGrid {
    constructor(game) {

        this.game = game;

        this.screenWidth = this.game.config.width;
        this.screenHeight = this.game.config.height;

        this.x = 0;

        if (this.screenWidth > this.screenHeight) {
            this.width = this.screenHeight / 2;
            this.x = (this.screenWidth - this.width) / 2
        } else {
            this.width = this.screenWidth;
        }
    
        this.height = this.screenHeight;
        this.cols = 11;
        this.rows = 11;
        this.cw = this.width / this.cols;
        this.ch = this.height / this.rows;
        
    }
    show(scene) {
        this.graphics = scene.add.graphics();
        this.graphics.lineStyle(2, 0xffffff);

        for (var i = 0; i < this.width; i += this.cw) {
            this.graphics.moveTo(this.x + i, 0);
            this.graphics.lineTo(this.x + i, this.height);
        }

        for (var i = 0; i < this.height; i += this.ch) {
            this.graphics.moveTo(this.x, i);
            this.graphics.lineTo(this.width + this.x, i);
        }

        this.graphics.strokePath();
    }
    placeAt(xx, yy, obj) {
        var x2 = this.cw * xx + this.cw / 2;
        var y2 = this.ch * yy + this.ch / 2;

        obj.x = this.x + x2;
        obj.y = y2
    }
    placeAtIndex(index, obj) {
        var yy = Math.floor(index / this.cols);
        var xx = index - (yy * this.cols);
        this.placeAt(xx, yy, obj);
    }
    showNumbers(scene) {
        this.show(scene);
        var count = 0;
        for (var i = 0; i < this.rows; i++) {
            for (var j = 0; j < this.cols; j++) {
                var numText = scene.add.text(0, 0, count, {color: '#ffffff'});
                numText.setOrigin(0.5, 0.5);
                this.placeAtIndex(count, numText);
                count++;
            }
        }
    }

    scaleToGameW(obj, per) {
        obj.displayWidth = this.width * per;
        obj.scaleY = obj.scaleX;
    }

    scaleToGameH(obj, per) {
        obj.displayHeight = this.height * per;
        obj.scaleX = obj.scaleY;
    }

    center(obj, scene) {
        obj.x = this.width/2;
        obj.y = this.height/2;
    }
    
    centerH(obj, scene) {
        obj.x = this.width/2;
    }

    centerV(obj, scene) {
        obj.y = this.height/2;
    }

}