import Phaser from "phaser";
import {Howl, Howler} from "howler";

import ee from './classes/events';
import Profile from "./classes/profile";
import MediaManager from "./classes/util/mediaManager";
import SceneLoad from "./scenes/sceneLoad";
import SceneTitle from "./scenes/sceneTitle";
import SceneOne from "./scenes/sceneOne";
import SceneTwo from "./scenes/sceneTwo";
import SceneTyping from "./scenes/sceneTyping";
import Maa from "./classes/maa";
import fonts from "./classes/ui/fonts";
import AlignGrid from "./classes/util/alignGrid";


// import SceneThree from "./scenes/sceneThree";
// import SceneOver from "./scenes/sceneOver";

    
const config = {
    type: Phaser.AUTO,
    backgroundColor: '#160100',
    width: window.innerWidth,
    height: window.innerHeight,
    scale: {
        width: 1024,
        height: 2048,
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    parent: 'phaser-game',
    //scene: [SceneLoad, SceneTitle, SceneOne, SceneTwo, SceneThree, SceneOver]
    scene: [SceneLoad, SceneTitle, SceneOne, SceneTwo, SceneTyping]
}; 

const game = new Phaser.Game(config);
game.alignGrid = new AlignGrid(game);
game.ee = ee;
game.profile = new Profile(game);
game.mm = new MediaManager(game);
game.maa = new Maa(game);

