class Profile {
    constructor(game) {
        this._score = 0;
        this.speedLimit = 30;
        this._speed = 10;
        this._revealButtons = 2;
        this._streak = 0;
        this.game = game
        this._soundsOn = true;
        this._musicOn = true;
     

        this.score = parseInt(this.updateFromCookie('score', 0));
        this.speed = parseInt(this.updateFromCookie('speed', 10));
        this.revealButtons = parseInt(this.updateFromCookie('revealButtons', 2));
        this.soundsOn = (this.updateFromCookie('soundsOn', 'true') === 'true');
        this.musicOn = (this.updateFromCookie('musicOn', 'true') === 'true');
        this.game.ee.emit('score_updated');
    }

    updateFromCookie(name, defaultValue) {
        var cookie = this.getCookie(name);
        if (cookie == "") {
            this.setCookie(name, defaultValue, 365);
            return defaultValue;
        } else {
            return cookie;
        }
    }

    set score(val) {
        this._score = val;
        this.setCookie("score", val, 365);
        gtag('event', 'progress', {'event_category': 'score','event_label': 'Score','value': val});
        this.game.ee.emit('score_updated');
    }

    get score() {
        return this._score;
    }

    set speed(val) {
        if (val <= this.speedLimit) {
            this._speed = val;
        } else {
            this._speed = this.speedLimit;
        }
        
        this.setCookie("speed", val, 365);
        gtag('event', 'progress', {'event_category': 'speed','event_label': 'Speed','value': val});
        this.game.ee.emit('speed_updated');
    }

    get speed() {
        return this._speed;
    }

    set revealButtons(val) {
        this._revealButtons = val;
        this.setCookie("revealButtons", val, 365);
        gtag('event', 'progress', {'event_category': 'revealButtons','event_label': 'Revealed Buttons','value': val});
        this.game.ee.emit('revealButtons_updated');
    }

    get revealButtons() {
        return this._revealButtons;
    }

    set streak(val) {
        this._streak = val;
        this.game.ee.emit('streak_updated');
    }

    get streak() {
        return this._streak;
    }

    set musicOn(val) {
        this._musicOn = val;
        this.setCookie("musicOn", val, 365);
        this.game.ee.emit('music_changed');
    }

    get musicOn() {
        return this._musicOn;
    }

    set soundsOn(val) {
        this._soundsOn = val;
        this.setCookie("soundsOn", val, 365);
        this.game.ee.emit('sound_changed');
    }

    get soundsOn() {
        return this._soundsOn;
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}

export default Profile;