export default class Transmission {
    constructor(scene, speed) {
        this.scene = scene;
        this.game = scene.game;
        this.mm = this.game.mm;
        this.ee = this.game.ee;
        this.profile = this.game.profile;
        this.active = false;
        this.taps = [];
        this.durations = [];
        this.pattern = '';
        this.speed = speed;
        this.delay = 10 / this.speed * 1000;
        this.progress = 0;
        this.animationFrame = 0;
        this.debounceMs = 25;

        // let spacers = document.getElementsByClassName("spacer");

        // for (let index = 0; index < spacers.length; index++) {
        //     const spacer = spacers[index];
        //     spacer.addEventListener('click', (e) => {
        //         this.interrupt();
        //     }); 
        // }

        this.scene.input.on('pointerdown', this.interrupt, this);
    }

    interrupt() {
        if (this.animationFrame < 3 && this.taps.length < 5) {
            this.mm.playSound('tap', this.scene);
            if (window.navigator) {
                window.navigator.vibrate(100);
            }

            if (this.tween) {
                this.tween.remove();
            }
            
            this.active = true;
            this.progress = 0;
            this.animationFrame = 0;
            let now = this.scene.frame;
            let lastTap = this.taps[this.taps.length-1];
            let duration;
            if (lastTap) {
                duration = now - lastTap;
            } else {
                duration = 0;
            }
            
            if (duration > this.delay) {
                this.pattern += '01';
            } else {
                this.pattern += '1';
            }
            this.durations.push(duration);
            this.taps.push(now);
            
            this.tween = this.scene.tweens.add({targets: this, duration: this.delay * 2, progress: 1, onUpdate: this.updated, onUpdateScope: this, onComplete: this.finish, onCompleteScope: this});
            this.ee.emit('transmission_interrupted', this);
        } else {
            // destroy transmission here
        }
    }

    updated() {
        
        let animationFrameBefore = this.animationFrame;
        
        if (this.progress > 0 && this.progress < 0.5) {
            this.animationFrame = 1;
        } else if (this.progress > 0.5 && this.progress < 1) {
            this.animationFrame = 2;
        } else if (this.progress >= 1) {
            this.animationFrame = 3;
            this.active = false;
        }
        if (animationFrameBefore !== this.animationFrame) {
            this.ee.emit('transmission_frame_updated', this);
        }
        
    }
    finish() {
        
        //this.transmission.destroy();
        //this.clear();
        //this.ee.emit('transmission_finished', this);
        this.send();
        //this.scene.tweens.add({targets: this.scene.keyboard, duration: 100, scale:1.05, yoyo: true, onComplete: this.destroy, onCompleteScope: this });
   
    }

    send() {
        if (this.scene.target.sector.sectorPattern === window.transmission.pattern) {
            this.game.maa.vibration(500);
            this.profile.score += this.scene.target.sector.number; 
            this.profile.streak += 1; 
            this.scene.target.destroy();
            this.scene.target = false;
        } else {
            this.profile.streak = 0;
        }

        this.ee.emit('transmission_sent', this);

        //this.tweens.add({targets: this.keyboard, duration: 100, scale:1.05, yoyo: true, onComplete: this.clear, onCompleteScope: this });
    }
    
    destroy() {
        this.taps = [];
        this.durations = [];
        this.pattern = '';
        this.progress = 0;
        this.animationFrame = 0;
    }
}