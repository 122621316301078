import ee from './../events';

export default class ScoreBox extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene);
        this.scene = scene;
        this.ee = scene.game.ee;
        this.profile = this.scene.game.profile;
        this.x = this.scene.game.config.width / 2;

        this.y = 100;
        this.lineHeight = 100;
        this.textStyle = {
            fontSize: '6em',
            fontStyle: 'normal',
            fontFamily: 'PROXON',
            color: '#ffffff',
            stroke: '#000000',
            align: 'center',
            strokeThickness: 1,
        }

        this.levelText = this.scene.add.text(0, 0, this.scene.levelName, this.textStyle);
        this.levelText.setOrigin(0.5, 0.5);

        this.add(this.levelText);

        this.scoreText = this.scene.add.text(0, this.lineHeight, "Score: " + this.profile.score, this.textStyle);
        this.scoreText.setOrigin(0.5, 0.5);

        this.add(this.scoreText);

        this.speedText = this.scene.add.text(0, this.lineHeight * 2, "Speed: " + this.profile.speed + ' dHz', this.textStyle);
        this.speedText.setOrigin(0.5, 0.5);

        this.add(this.speedText);

        this.streakText = this.scene.add.text(0, this.lineHeight * 3, "Streak: " + this.profile.streak + "/" + this.scene.streakThreshold, this.textStyle);
        this.streakText.setOrigin(0.5, 0.5);

        this.add(this.streakText);

        if (this.scene.hintText) {
            this.textStyle.color = '#ffff00';
            this.textStyle.fontSize = '8em';
            this.hintText = this.scene.add.text(0, this.lineHeight * 5, this.scene.hintText, this.textStyle);
            this.hintText.setOrigin(0.5, 0.5);
            this.add(this.hintText);
        }

        this.ee.on('score_updated', this.updated, this);
        this.ee.on('streak_updated', this.updated, this);
        this.ee.on('speed_updated', this.updated, this);

        this.scene.add.existing(this);
    }
    updated() {
        this.scoreText.setText("Score: " + this.profile.score);
        this.speedText.setText("Speed: " + this.profile.speed + ' dHz');
        this.streakText.setText("Streak: " + this.profile.streak + "/" + this.scene.streakThreshold);
        
    }
}