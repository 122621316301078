import ee from './../events';

export default class FlatButton extends Phaser.GameObjects.Container {
    constructor(config) {
        if (!config.scene) {
            console.log("Missing scene!");
            return;
        }
        if (!config.key) {
            console.log("Missing key!");
            return;
        }
        super(config.scene)

        this.config = config;
        this.textConfig = {
            fontSize: '6em', 
            fontStyle: 'bold',
            fontFamily: 'PROXON',
            stroke: '#000000',
            strokeThickness: 2,
            
        };
        this.scene = config.scene;
        this.back = this.scene.add.image(0, 0, config.key);   
        
        this.add(this.back);

        this.text1 = this.scene.add.text(0, 0, config.text, this.textConfig); 
        
        this.text1.setOrigin(0.5, 0.5);
        this.add(this.text1);

        if (config.x) {
            this.x = config.x;
        }

        if (config.y) {
            this.y = config.y;
        }

        this.scene.add.existing(this);

        if (config.event) {
            this.back.setInteractive();
            this.back.on('pointerdown', this.pressed, this);
        }

        // if (this.scene.model.isMobile == -1) {
        //     this.back.on('pointerover', this.over, this);
        //     this.back.on('pointerout', this.out, this)
        // }
    }

    over() {
        this.y -= 5;
    }

    out() {
        this.y += 5;
    }

    pressed() {
        if (this.config.params) {
            ee.emit(this.config.event, this.config.params);
        } else {
            ee.emit(this.config.event);
        }
        
    }
}