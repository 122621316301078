export default [
    {
        pattern: '1101',
        letter: 'A',
        notation: '21',
        number: 4,
        angle: -40
    },
    {
        pattern: '101111',
        letter: 'B',
        notation: '14',
        number: 23,
        angle: 11.25
    },
    {
        pattern: '1011011',
        letter: 'C',
        notation: '122',
        number: 25,
        angle: 55
    },
    {
        pattern: '10111',
        letter: 'D',
        notation: '13',
        number: 11,
        angle: 22.5
    },
    {
        pattern: '11',
        letter: 'E',
        notation: '2',
        number: 1,
        angle: 0
    },
    {
        pattern: '111011',
        letter: 'F',
        notation: '32',
        number: 17,
        angle: 58
    },
    {
        pattern: '101011',
        letter: 'G',
        notation: '112',
        number: 13,
        angle: -60
    },
    {
        pattern: '11111',
        letter: 'H',
        notation: '5',
        number: 15,
        angle: 11.25
    },
    {
        pattern: '111',
        letter: 'I',
        notation: '3',
        number: 3,
        angle: 45
    },
    {
        pattern: '11010101',
        letter: 'J',
        notation: '2111',
        number: 22,
        angle: -11.5
    },
    {
        pattern: '101101',
        letter: 'K',
        notation: '121',
        number: 12,
        angle: 67
    },
    {
        pattern: '110111',
        letter: 'L',
        notation: '23',
        number: 19,
        angle: -74
    },
    {
        pattern: '10101',
        letter: 'M',
        notation: '111',
        number: 6,
        angle: -40
    },
    {
        pattern: '1011',
        letter: 'N',
        notation: '12',
        number: 5,
        angle: 45
    },
    {
        pattern: '1010101',
        letter: 'O',
        notation: '1111',
        number: 14,
        angle: -22.5
    },
    {
        pattern: '1101011',
        letter: 'P',
        notation: '212',
        number: 21,
        angle: -34
    },
    {
        pattern: '10101101',
        letter: 'Q',
        notation: '1121',
        number: 28,
        angle: -56
    },
    {
        pattern: '11011',
        letter: 'R',
        notation: '22',
        number: 9,
        angle: -60
    },
    {
        pattern: '1111',
        letter: 'S',
        notation: '4',
        number: 7,
        angle: 22.5
    },
    {
        pattern: '101',
        letter: 'T',
        notation: '11',
        number: 2,
        angle: 0
    },
    {
        pattern: '11101',
        letter: 'U',
        notation: '31',
        number: 8,
        angle: 67
    },
    {
        pattern: '111101',
        letter: 'V',
        notation: '41',
        number: 16,
        angle: 35
    },
    {
        pattern: '110101',
        letter: 'W',
        notation: '211',
        number: 10,
        angle: -22.5
    },
    {
        pattern: '1011101',
        letter: 'X',
        notation: '131',
        number: 24,
        angle: 35
    },
    {
        pattern: '10110101',
        letter: 'Y',
        notation: '1211',
        number: 26,
        angle: 78
    },
    {
        pattern: '1010111',
        letter: 'Z',
        notation: '113',
        number: 27,
        angle: -74
    },
    {
        pattern: '101010101',
        letter: 'DEL',
        notation: '11111',
        number: 30,
        angle: -11.5
    },
    {
        pattern: '10101011',
        letter: '(',
        notation: '1112',
        number: 29,
        angle: -34
    },
    {
        pattern: '1101101',
        letter: '+',
        notation: '221',
        number: 20,
        angle: -56
    },
    {
        pattern: '1110101',
        letter: '_',
        notation: '311',
        number: 18,
        angle: 78
    },
    {
        pattern: '1',
        letter: 'OK',
        notation: '1',
        number: 0,
        angle: 0
    }
]