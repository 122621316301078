export default class Target {
    constructor(x, y, scene, keyboard, sector = null) {
        this.scene = scene;
        this.keyboard = keyboard;
        this.sector = sector ? sector : this.keyboard.randomSector();
        this.size = this.keyboard.width / 40;
        this.path = this.sector.path;
        this.point = this.path.getPoint(0.8);
        this.mm = this.scene.game.mm;
        // this.graphics.lineStyle(1, 0xffffff, 1);
        // this.path.draw(this.graphics);
        this.draw();
    }
    draw() {
        this.star = this.scene.add.star(this.keyboard.x + this.point.x, this.keyboard.y + this.point.y, 5, this.size / 2, this.size, 0xffff00);
        this.star.setOrigin(0.5, 0.5);
        this.star.setStrokeStyle(2, 0x000000, 1);
    }
    destroy() {
        this.star.destroy();
        this.mm.playSound('congrats', this.scene);
    }
}