export default class Maa {
    constructor(game) {
        this.game = game;
        this.mm = this.game.mm;
        this.delayDuration = 10 / (this.game.profile.speed - 1) * 1000 / 2;
    }
    async say(pattern, scene) {
        
        for (let index = 0; index < pattern.length; index++) {

            const bit = pattern[index];

            if (bit == '1') {
                scene.tweens.add({
                    targets: this,
                    from: 0,
                    to: 1,
                    ease: 'Linear',
                    duration: this.delayDuration * index + 1,
                    onComplete: function () {
                        this.sendFeedback(scene);
                    },
                    onCompleteScope: this,
                });
            }

            //await this.delay(this.delayDuration);
        }
        
    }
    sendFeedback(scene) {
        this.sound(scene);
        this.vibration(100);
    }

    vibration(duration) {
        if (window.navigator) {
            window.navigator.vibrate(duration);
        }
    }

    sound(scene) {
        this.mm.playSound('chime', scene);
    }

    delay(delayInms) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(2);
          }, delayInms);
        });
      }
}