export default class Player {
    constructor(scene, keyboard) {
        this.scene = scene;
        this.keyboard = keyboard;
        this.graphics = this.scene.add.graphics();
        this.size = 12;
        scene.game.ee.on('transmission_interrupted', this.draw, this);
    }

    draw(transmission) {
        
        this.graphics.clear();
        if (this.tween) {
            this.tween.remove();
        }
        
        this.t = 0.2;
        let activeSector = this.keyboard.getActiveSector();

        if (activeSector) {

                this.path = activeSector.path;
  
                this.tween = this.scene.tweens.add({targets: this, duration: transmission.delay * 2, t:0.8, onUpdate: this.move, onUpdateScope: this, onComplete: this.destroy, onCompleteScope: this });
           
        }
    }
    destroy() {
        this.graphics.clear();
    }
    move() {
        this.destroy()
        // this.graphics.lineStyle(1, 0xffffff, 1);
        // this.path.draw(this.graphics);
        if (this.t < 1) {
            let point = this.path.getPoint(this.t);
            this.graphics.fillStyle(0x000000, 1);
            this.graphics.fillCircle(this.keyboard.x + point.x, this.keyboard.y + point.y, this.size);
        } 
        
    }
    
}