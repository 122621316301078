export default class Goal extends Phaser.GameObjects.Container {
    constructor(x, y, scene, keyboard, sector = null, showColor = true) {
        super(scene);
        this.scene = scene;
        this.x = x;
        this.y = y;
        this.mm = scene.game.mm;
        this.keyboard = keyboard;
        this.showColor = showColor;
        this.sector = sector ? sector : this.getRandomIndex();
        this.size = keyboard.width / 10;
        this.circle = scene.add.circle(0, 0, this.size, 0xffffff);
        if (this.showColor) {
            this.lineColor = this.sector.lineColor;
        } else {
            this.lineColor = 0x000000;
        }
        this.circle.setStrokeStyle(12, this.lineColor, 20);
        this.add(this.circle);
        this.textStyle = {
            fontSize: '8em',
            fontStyle: 'bold',
            fontFamily: 'PROXON',
            color: 0xffffff
        }
        this.text = scene.make.text({x:0, y: 0, text: this.sector.letter, style: this.textStyle, add: false});
        this.text.setOrigin(0.5, 0.4);
        this.add(this.text);
        this.setSize(this.radius*2,this.radius*2);

        this.scene.game.alignGrid.placeAtIndex(49, this);

        scene.add.existing(this);
    }
    getRandomIndex() {
        return this.keyboard.randomSector();
        //return this.keyboard.sectors[4];
    }
    destroy() {
        this.circle.destroy();
        this.text.destroy();
        this.mm.playSound('congrats', this.scene);
    }
}