import ScoreBox from "../classes/comps/scoreBox";
import Transmission from "../classes/transmission";
import Keyboard from "../classes/keyboard";
import Target from "../classes/goal";
import Player from "../classes/player";
import SoundButtons from "../classes/ui/soundButtons";
import AlignGrid from "../classes/util/alignGrid";
import queryString from 'query-string';

export default class SceneTyping extends Phaser.Scene {
    constructor() {
        super('SceneTyping');
        this.levelName = 'Typing';
        this.frame = 0;
        this.speedLimit = 30;
        this.scoreLimit = 99999999999999;
        this.padding = 10;
        this.maxButtons = 30;
        this.streakThreshold = 10;
        
    }
    preload() {
    }
    create() {
        const parsed = queryString.parse(location.search);

        this.buttons = parsed.buttons || 30;
        this.speed = parsed.speed || 20;


        gtag('event', 'scene', {'value': 'Scene Typing'});
        this.bg = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bg2');
        
        this.bg.setScale(1.5);
        this.bg.rotation = 2.5;

        //this.game.alignGrid.showNumbers();
        this.game.mm.setBackgroundMusic('bg_01', this);
        //this.soundButtons = new SoundButtons(this);
        this.game.ee.on('transmission_sent', this.clear, this);
        this.game.ee.on('streak_updated', this.treat, this);

        // this.scoreBox = new ScoreBox(this);

        // this.scoreBox.x = this.game.config.width / 2;
        // this.scoreBox.y = 50

        window.transmission = new Transmission(this, this.speed);
       
        this.keyboard = new Keyboard(this, true, this.buttons);

        this.target = new Target(this.game.config.width / 2, 100, this, this.keyboard, null, true);

        this.player = new Player(this, this.keyboard);

    }

    treat() {
        // if (this.game.profile.streak >= this.streakThreshold) {
        //     this.game.mm.playSound('levelUp', this);
        //     this.game.profile.streak = 0;
            
        //     // if (this.game.profile.revealButtons < this.maxButtons) {
        //     //     this.keyboard.setLimit(++this.game.profile.revealButtons);   
        //     // } 
        //     // if (this.game.profile.speed < this.speedLimit) {
        //     //     this.game.profile.speed ++;   
        //     // } 
        // }
    }

    clear() {
        window.transmission.destroy();
        this.time.addEvent({ delay: 1000, callback: this.newRound, callbackScope: this, loop: false });
    }

    newRound() {
  
        this.keyboard.draw();
        if (!this.target) {
            this.nextTarget = new Target(this.game.config.width / 2, 400, this, this.keyboard, null, true);
            //this.game.maa.say(this.nextTarget.sector.sectorPattern, this);
            this.target = this.nextTarget;
        }
        
    }

    update(frame) {
        this.frame = frame;
        //this.bg.rotation += 0.00025;
    }
}