import ScoreBox from "./../classes/comps/scoreBox";
import Transmission from "./../classes/transmission";
import Keyboard from "./../classes/keyboard";
import Target from "./../classes/target";
import Player from "./../classes/player";
import SoundButtons from "./../classes/ui/soundButtons";
import AlignGrid from "./../classes/util/alignGrid";

export default class SceneOne extends Phaser.Scene {
    constructor() {
        super('SceneOne');
        this.levelName = 'Level 1';
        this.hintText = 'Get the star!';
        this.frame = 0;
        this.speedLimit = 15;
        this.scoreLimit = 300;
        this.padding = 10;
        this.maxButtons = 6;
        this.streakThreshold = 10;
    }
    preload() {
    }
    create() {
        gtag('event', 'scene', {'value': 'Scene One'});
        this.bg = this.add.image(this.game.config.width / 2, this.game.config.height / 2, 'bg2');
        
        this.bg.setScale(2);
        this.bg.rotation = 1;


        this.game.mm.setBackgroundMusic('bg_01', this);
        //this.soundButtons = new SoundButtons(this);
        this.game.ee.on('transmission_sent', this.clear, this);
        this.game.ee.on('streak_updated', this.treat, this);

        this.scoreBox = new ScoreBox(this);
        this.scoreBox.y = this.game.config.height / 5;
        
        window.transmission = new Transmission(this, this.game.profile.speed);
        
        this.keyboard = new Keyboard(this, false, this.game.profile.revealButtons);
        
        this.target = new Target(null, null, this, this.keyboard);
        this.player = new Player(this, this.keyboard);
            
    }

    treat() {
        if (this.game.profile.streak >= this.streakThreshold) {
            this.game.profile.streak = 0;
            
            if (this.game.profile.revealButtons < this.maxButtons) {
                this.keyboard.setLimit(++this.game.profile.revealButtons);    
            } 
            if (this.game.profile.speed < this.speedLimit) {
                this.game.profile.speed ++;  
                this.game.mm.playSound('levelUp', this);
            } 
        }
    }

    clear() {
        window.transmission.destroy();
        this.time.addEvent({ delay: 1000, callback: this.newRound, callbackScope: this, loop: false });
    }
    
    newRound() {
        
        if (this.game.profile.score > this.scoreLimit && this.game.profile.speed >= this.speedLimit && this.game.profile.revealButtons >= this.maxButtons) {
            this.game.ee.destroy();
            this.game.mm.playSound('nextLevel', this);
            this.scene.start('SceneTwo');
        } else {
            this.keyboard.draw();
            if (!this.target) {
                this.nextTarget = new Target(null, null, this, this.keyboard);
                this.game.maa.say(this.nextTarget.sector.sectorPattern, this);
                this.target = this.nextTarget;
            }
        }
    }
    update(frame) {
        this.frame = frame;
        //this.bg.rotation += 0.00025;
    }

}