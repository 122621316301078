import ToggleButton from "./toggleButton";

export default class SoundButtons extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene);
        this.scene = scene;
        this.ee = this.scene.game.ee;
        this.profile = this.scene.game.profile;

        this.musicButton = new ToggleButton({scene: this.scene, backKey: 'toggleBack', onIcon: "musicOn", offIcon: "musicOff", event: "toggle_music"});
        this.sfxButton = new ToggleButton({scene: this.scene, backKey: 'toggleBack', onIcon: "sfxOn", offIcon: "sfxOff", event: "toggle_sounds"});
        
        
        this.add(this.musicButton);
        this.scene.game.alignGrid.placeAtIndex(0, this.musicButton);
        
        
        this.add(this.sfxButton);
        this.scene.game.alignGrid.placeAtIndex(10, this.sfxButton);
        

        if (!this.profile.musicOn) {
            this.musicButton.toggle();
        }

        if (!this.profile.soundsOn) {
            this.sfxButton.toggle();
        }

        this.ee.on('toggle_music', this.toggle_music, this);
        this.ee.on('toggle_sounds', this.toggle_sounds, this);

        this.scene.add.existing(this);
    }

    toggle_music() {
        this.profile.musicOn = !this.profile.musicOn;
    }

    toggle_sounds() {
        this.profile.soundsOn = !this.profile.soundsOn;
    }
}