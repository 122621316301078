
import Bar from "../classes/comps/bar";

export default class SceneLoad extends Phaser.Scene {
    constructor() {
        super('SceneLoad');
    }

    preload() {
        this.textStyle = {
            fontSize: '4em',
            fontStyle: 'normal',
            fontFamily: 'PROXON',
            color: '#ffffff',
            align: 'center',
            lineSpacing: 2,
            wordWrap: {
                width: this.game.alignGrid.width * 0.8
            },
        }
        this.loadingText = this.add.text(this.game.config.width / 2, this.game.config.height / 2 - 100, "loading...", this.textStyle);

        this.loadingText.setOrigin(0.5, 0.5);
        
        this.bar = new Bar({scene: this, x: this.game.config.width / 2, y: this.game.config.height / 2});

        this.progText = this.add.text(this.game.config.width/2, this.game.config.height/2, "0%", { color: '#ffffff', fontSize: '3em', fontFamily: 'PROXON'});
        this.progText.setOrigin(0.5, 0.5);
        this.load.on('progress', this.onProgress, this);
        this.load.image('title', 'images/game-logo.png');
        this.load.image('bg', 'images/bg/bg_01.jpg');
        this.load.image('bg2', 'images/bg/bg_02.jpg');
        this.load.image("button1", "images/ui/buttons/3/button_large.png");

   
        this.load.image("button2", "images/ui/buttons/2/5.png");
        this.load.audio("tap", ["audio/tap_02.mp3"]);
        this.load.audio("bubble", ["audio/bubble.mp3"]);
        this.load.audio("chime", ["audio/chime_01.mp3"]);
        this.load.audio("bark", ["audio/bow.mp3"]);
        this.load.audio("kick", ["audio/kick.mp3"]);
        this.load.audio("snare", ["audio/snare.mp3"]);
        this.load.audio("congrats", ["audio/achievement_03.mp3"]);
        this.load.audio("levelUp", ["audio/achievement_02.mp3"]);
        this.load.audio("nextLevel", ["audio/achievement_05.mp3"]);
        this.load.audio("backgroundMusic", ["audio/logo_music.mp3"]);
        this.load.audio("bg_01", ["audio/bg_01.mp3"]);
        this.load.image("toggleBack", "images/ui/toggles/4.png");
        this.load.image("sfxOff", "images/ui/icons/sfx_off.png");
        this.load.image("sfxOn", "images/ui/icons/sfx_on.png");
        this.load.image("musicOn", "images/ui/icons/music_on.png");
        this.load.image("musicOff", "images/ui/icons/music_off.png");

        //this.load.spritesheet("keyboardParts", "images/spritesheet.png", {frameWidth: 361, frameHeight: 361});
        this.load.atlas("keyboardParts", "images/spritesheet.png", "images/spritesheet.json");
    }

    onProgress(value) {
        var per = Math.floor(value * 100)
        this.progText.setText(per);
        this.bar.setPercent(value);

    }

    create() {
        this.scene.start("SceneTitle");
    }
}